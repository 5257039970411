import { mapActions } from 'vuex'

export default {
  data: () => ({
        loading: false,
        selected: [],
        message: '',
        error: false,
        showMessage: false,
        errors: []
    }),
    
    methods: { 
      ...mapActions('group', ['ActionGenerateGridsAll']),
        
    save(){
      
      if(this.loading){
        return;
      }
      
      this.loading = true;
      this.error   = false;
      this.showMessage = false;
      this.errors = [];
      this.message = '';

      var payload = {
        items: this.selected,
        generateAll: true,
      };

      this.ActionGenerateGridsAll(payload)
            .then((res) => {
              this.message    = 'Oferta(s) gerada com sucesso!';
            })
            .catch((error) =>{
              
              this.error      = true;
              
              if(error.errors.items){
                this.errors = error.errors.items;
              }else{
                this.errors = error.errors;
              }
            })
            .finally(() => {
              this.loading = false;
              this.showMessage = true;
            });
    }

  },

}
